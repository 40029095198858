<template>
  <div
    class="relative font-dinPro"
    :class="{
      'mb-12': !hasSearchIcon && !small,
      'mb-0': small,
    }"
  >
    <div
      class="relative h-40"
      :class="{
        'sm:h-48': !small,
      }"
    >
      <img
        v-if="hasSearchIcon && !loading"
        :src="'/icons/icon-search.svg?url'"
        class="h-16 w-16 sm:h-20 sm:w-20 absolute top-1/2 transform -translate-y-1/2 left-16 z-10"
        alt="search"
        height="20"
        width="20"
      >
      <img
        v-else-if="hasSearchIcon && loading"
        :src="'/lottie-animations/spinner-svg.svg?url'"
        :height="16"
        :width="16"
        class="h-16 w-16 absolute top-1/2 transform -translate-y-1/2 left-16 z-10"
      >
      <input
        id="text-input"
        ref="textInput"
        :value="input"
        class="input text-[16px] text-small-regular relative w-full border border-grey400 rounded-md h-full px-16 animate-all pt-2 focus:shadow-border-black"
        :class="{
          'pl-40 sm:pl-48': hasSearchIcon,
          'pr-48': error && error?.length > 0 || hasSuccess,
          '!border-error !shadow-error': error && error?.length > 0,
          'border-success shadow-success': hasSuccess,
          'hover:border-black focus:border-black': !error?.length > 0 && !hasSuccess,
          '!border-0 focus:!border-0 focus:shadow-none': noBorder,
          'rounded-b-none': roundedB
        }"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :type="type"
        :pattern="type === 'number' ? '\d*' : ''"
        :autofocus="hasAutofocus"
        @input="(e) => (input = e.target?.value)"
        @blur="unsetFocus"
        @focus="setFocus"
        @keyup.enter="$emit('enter-submit')"
      >
      <div
        v-if="hasSuccess"
        class="h-24 w-24 absolute top-1/2 right-8 transform -translate-y-1/2 text-sm text-success pointer-events-none"
        :class="{
          'border-success': hasSuccess,
        }"
      >
        <img
          class="text-success"
          :src="'/icons/icon-checkmark-circle-green.svg?url'"
          alt="success circle"
          height="24"
          width="24"
        >
      </div>
      <div
        v-else-if="error && error?.length > 0"
        class="h-24 w-24 absolute top-1/2 right-8 transform -translate-y-1/2 text-sm text-error border-error cursor-pointer"
        @click="input = ''"
      >
        <img
          :src="'/icons/icon-x-circle.svg?url'"
          alt="Error circle"
          height="24"
          width="24"
        >
      </div>
    </div>
    <div v-if="error && error?.length > 0" class="text-xsmall-medium mt-6 text-error">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import debounce from '~/util/debounce';

const props = defineProps<{
  testModeStatus?: boolean,
  placeholder?: string,
  autocomplete?: string,
  type?: string,
  initValue?: string,
  icon?: string,
  iconStyle?: string,
  iconColor?: string,
  error?: string,
  id?: string,
  hasSearchIcon?: boolean,
  hasSuccess?: boolean,
  isTextArea?: boolean,
  loading?: boolean,
  noDelaySubmit?: boolean,
  small?: boolean,
  noBorder?: boolean,
  roundedB?: boolean,
  hasAutofocus?: boolean,
  delayValue?: number
}>();

const input = ref('');
const hasFocus = ref(false);
const textInput = ref();

let submitInputDebounced = () => {};

watch(input, () => {
  submitInputDebounced();
});

watch(
  () =>props.initValue,
  (newValue) => {
    if (newValue) {
      input.value = newValue;
    }
  }
);

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    if (props.hasSearchIcon && !route.query.query) {
      input.value = '';
    }
  }
);

onMounted(() => {
  if (props.delayValue) {
    submitInputDebounced = debounce(submitInput, props.delayValue);
  } else if (props.noDelaySubmit) {
    submitInputDebounced = debounce(submitInput, 0);
  } else {
    submitInputDebounced = debounce(submitInput, 1000);
  }
  input.value = props.initValue ? props.initValue : '';
});

const emit = defineEmits<{
  (event: 'submitInput', input?: string, id?: string): void,
  (event: 'onFocus'): void,
  (event: 'onBlur'): void,
  (event: 'enter-submit'): void,
}>();

const submitInput = () => {
  emit('submitInput', input.value ? input.value : '', props.id);
};

const setFocus = () => {
  emit('onFocus');
  hasFocus.value = true;
};

const unsetFocus = () => {
  emit('onBlur');
  setTimeout(() => {
    hasFocus.value = false;
  }, 200);
};
</script>
